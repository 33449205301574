import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const { previous, next } = data;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="max-w-4xl mx-auto">
        <article
          className="blog-post dark:text-gray-300 mt-24 px-8"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1
              className="text-gray-900 dark:text-gray-300 text-2xl sm:text-3xl lg:text-4xl font-medium text-center mb-4"
              itemProp="headline"
            >
              {post.frontmatter.title}
            </h1>
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
        <nav>
          <ul className="flex flex-wrap justify-between list-none">
            <li className="text-brand cursor-pointer inline-flex items-center md:mb-2 lg:mb-0 mt-8">
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li className="text-brand cursor-pointer inline-flex items-center md:mb-2 lg:mb-0 mt-8">
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
